import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import Newsletter from "./Newsletter";

const root = ReactDOM.createRoot(document.getElementById("tgcnewsletter-root"));

root.render(
  <React.StrictMode>
    <Newsletter />
  </React.StrictMode>
);
